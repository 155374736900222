import React from 'react';
import '../styles/headerStyles.scss';
import Menu from "../assets/img/menu-icon.svg";
import "../styles/tailwind.css";
import { Link, navigate } from 'gatsby';
import { motion } from 'framer-motion';




const header = ({setToggleMenu, toggleMenu}) => {

  return (
   <>
    <motion.nav
         animate={{ y: 0, opacity: 1 }}
         initial={{ y: -72, opacity: 0 }}
         transition={{
           duration: 1,
           ease: [0.6, 0.05, -0.01, 0.9],
         }}
         className="headerNav"
    >
      <Link
      to="/"
      >
       <div className="logo">
        <span className="next">next.</span>
        <span className="legal">legal</span>
      </div>
      </Link>
      <ul className="nav">
        {/* <li className="hide-menu" onClick={() => { navigate('/agenda')} } >agenda</li> */}
        <Link
        to="/about"
        >
        <li className="hide-menu font-semibold text-gray-800">About</li>
        </Link>
        <li className="hide-menu">
        <button className="px-6 py-3 tracking-wider bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white font-semibold border border-gray-800 hover:border-transparent rounded-full" onClick={() => navigate('/events') }>Past events</button>
          {/* <button className="px-6 py-2 text-white font-light tracking-wider bg-gray-900 rounded-full events" onClick={() => { navigate('/events')} }>past events</button> */}
          </li>
        <li className="menu"><button onClick={() => setToggleMenu(!toggleMenu)}><img src={Menu} alt="menu button"/></button></li>
      </ul>
    </motion.nav>
    <div className="line"></div>
   </>
  )
}

export default header
