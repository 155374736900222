import React from 'react'
import Cancel from '../assets/img/canel-icon.svg'
import '../styles/menuStyles.scss'
import { motion, AnimatePresence } from "framer-motion"
import { Link, navigate } from 'gatsby'

const menu = ({ toggleMenu, setToggleMenu }) => {

    return (<>
    <AnimatePresence>
    { toggleMenu && (
        <motion.div className="menu-container"
            initial={{ x: "-100%" }}
            exit={{ x: "-100%" }}
            animate={{ x: toggleMenu ? 0 : "-100%" }}
            transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
            >
                <nav>
                    <div className="logo">
                    </div>
                    <ul className="nav">
                        <li className="menu"><button onClick={ () => setToggleMenu(!toggleMenu) } className="cancel"><img src={Cancel} alt="close button"/></button></li>
                    </ul>
                </nav>
                <div className="line"></div>
                <ul className="nav-links">
                    {/* <li className="link" onClick={() => { navigate('/agenda')} }>agenda</li> */}
                    <Link
                    to="/about"
                    >
                    <li className="link" onClick={ () => setToggleMenu(!toggleMenu) }>about</li>
                    </Link>
                    <li className="link" onClick={() => { navigate('/events')} }>past events</li>
                    <Link
                    to="/privacy"
                    className="pp"
                    >
                    <li className="link">privacy policy</li>
                    </Link>
                </ul>
                <div className="social-links">
                <a href="https://www.facebook.com/bytelexhq/" target="_blank" rel="noopener norefferer">
                    <span className="sl">FB.</span>
                </a>
                <a href="https://www.facebook.com/bytelexhq/" target="_blank" rel="noopener norefferer">
                    <span className="sl">TW.</span>
                </a>
                <a href="https://www.facebook.com/bytelexhq/" target="_blank" rel="noopener norefferer">
                    <span className="sl">LI.</span>
                </a>
                    
                </div>
                <p className="copyright">©{new Date().getFullYear()} Bytelex Advocates</p>
            </motion.div>
    )
        } 
        </AnimatePresence>
        </>
    )
}

export default menu
