import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {theme, CSSReset, ThemeProvider } from "@chakra-ui/core";
import  styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import Header from "./header";
import Menu from "../components/menu";
import { useIdentityContext, IdentityContextProvider } from "react-netlify-identity-widget"



const GlobalStyle = createGlobalStyle`
${normalize}
:root {
  --primary: #ABCB4C;
  --secondary: #006BB4; 
  --logoHeading:2.618em;
  --fontPara:"Open Sans"
}


* {
  text-decoration: none;
  
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    height: 100%;
    
  
  
}
body {
  font-size: 16px;
  font-family: 'Montserrat', 'Uni Neue' -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #F8F7F5; 
  
  overscroll-behavior: none;
  overflow-x: hidden;
  color:black;
  height: 100%;
}

h1{
  font-size: 2.618em;
}

h2{
  font-size: 1.618em;
}

p{
  font-size: 1.1250em; 
}
`



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

//menu function
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
<ThemeProvider theme={theme}>
  <GlobalStyle/>
  <CSSReset/>
  <Menu
   toggleMenu={toggleMenu}
   setToggleMenu={setToggleMenu}
  />
  <Header
   toggleMenu={toggleMenu}
   setToggleMenu={setToggleMenu}
  />
  <IdentityContextProvider url={"https://nextlegal.io/"}>
    <main>{children}</main>
  </IdentityContextProvider>
  
</ThemeProvider>
  
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
